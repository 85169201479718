import React from "react";
import close from "../images/close.png";

const LrgImg = (props) => {
  return (
    <div>
      <div className="lg-img-hldr">
        <img src={props.src} alt="Пример работы" />
        <div className="top-bar">
          <a href=" " onClick={props.colseImage}>
            <img img src={close} className="close-icon" alt="Пример работы" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default LrgImg;

import React from "react";

class Menu extends React.Component {
  render() {
    return (
      <div className="Menu sticky">
        <div className="Wrapper">
          <a href="#Аdvantages">О компании</a>
          <a href="#Technology">Технология</a>
          <a href="#Сalculator">Калькулятор</a>
          <a href="#Gallery">Галерея</a>
          <a href="#Connection">Контакты</a>
        </div>
      </div>
    );
  }
}

export default Menu;

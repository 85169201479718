import React from "react";
import example from "../images/example.jpg";

class AboutUs extends React.Component {
  render() {
    return (
      <div className="AboutUs">
        <img src={example} alt="Пример работы" />
        <div>
          <h1>Сухопресованная стяжка пола</h1>
          <p>
            Сделаем стяжку вашего дома ,квартиры, любого коммерческого
            помещения, быстро качественно и недорого! Опыт работы и мастерство
            профессионалов!
          </p>
        </div>
      </div>
    );
  }
}

export default AboutUs;

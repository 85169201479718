import React from "react";
import Img from "./Img";
import LrgImg from "./LrgImg";
import galery1 from "../images/galery1.jpg";
import galery2 from "../images/galery2.jpg";
import galery3 from "../images/galery3.jpg";
import galery4 from "../images/galery4.jpg";
import galery5 from "../images/galery5.jpg";
import galery6 from "../images/galery6.jpg";
import galery7 from "../images/galery7.jpg";
import galery8 from "../images/galery8.jpg";

class Gallery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imgLst: [
        {
          imgSrc: galery1,
        },
        {
          imgSrc: galery2,
        },
        {
          imgSrc: galery3,
        },
        {
          imgSrc: galery4,
        },
        {
          imgSrc: galery5,
        },
        {
          imgSrc: galery6,
        },
        {
          imgSrc: galery7,
        },
        {
          imgSrc: galery8,
        },
      ],
      imgStyle: {
        imgListConStyle: {
          display: "flex",
        },
        lrgImgConStyle: {
          display: "none",
        },
      },
      lrgImg: {
        src: "",
      },
    };
  }

  handleClick = (e, src) => {
    console.log("this action is woring");
    e.preventDefault();
    if (this.state.imgStyle.imgListConStyle.display === "flex") {
      this.setState({
        imgStyle: {
          imgListConStyle: {
            display: "none",
          },
          lrgImgConStyle: {
            display: "block",
          },
        },
      });

      console.log(e.target.src);

      this.setState({
        lrgImg: { src },
      });
    }
  };

  lrgImgClose = (e) => {
    e.preventDefault();
    this.setState({
      imgStyle: {
        imgListConStyle: {
          display: "flex",
        },
        lrgImgConStyle: {
          display: "none",
        },
      },
    });
  };

  render() {
    let imageItemList = this.state.imgLst.map((image) => {
      return (
        <button
          onClick={(event) => this.handleClick(event, image.imgSrc)}
          style={{ background: "none", border: "none", padding: "0" }}
          key={image.imgSrc}
        >
          <Img src={image.imgSrc} />{" "}
        </button>
      );
    });

    return (
      <React.Fragment>
        <div className="lg-img-con" style={this.state.imgStyle.lrgImgConStyle}>
          <LrgImg colseImage={this.lrgImgClose} src={this.state.lrgImg.src} />
        </div>
        <div className="Gallery" id="Gallery">
          <h3>Галерея</h3>
          <div className="Wrapper" style={this.state.imgStyle.imgListConStyle}>
            <div>{imageItemList}</div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Gallery;

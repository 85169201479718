import React from "react";

class Technology extends React.Component {
  render() {
    return (
      <div className="Technology" id="Technology">
        <h2>Технология</h2>
        <div className="Wrapper">
          <div className="Technologys">
            <div>
              <h3>Этапы работы:</h3>
              <p>
                <span>1.</span> Подготовительные работы
              </p>
              <p>
                <span>2.</span> Стелем пленку(по необходимости)
              </p>
              <p>
                <span>3.</span> Устанавливаем демпферную ленту
              </p>
              <p>
                <span>4.</span> Устанавливаем маяки по лазерному уровню
              </p>
              <p>
                <span>5.</span> Приготовление и подача раствора
              </p>
              <p>
                <span>6.</span> Выравнивание по маякам
              </p>
              <p>
                <span>7.</span> Финишная зашлифовка поверхности
              </p>
            </div>
            <div>
              <h3>Принцип работы:</h3>
              <p>Обработка заявки</p>
              <p>Бесплатный выезд специалиста</p>
              <p>Согласование цены и сроков</p>
              <p>Заключение договора (+ аванс)</p>
              <p>Выполнение работ</p>
              <p>Расчет</p>
            </div>
          </div>
          <h3>Оборудование и материалы</h3>
          <div className="Materials">
            <div>
              <h4>Гаситель</h4>
              <p>
                Полусухие растворы не всегда могут подаватся равновмерно и с
                одинаковым давлением. По этой причине используется такая машина
                для заливки полов, как гаситель. Раствор после пневмонагнетателя
                направляется к ним. Рабочее давление сводится к минимальным
                показателям, что позволяет с максимальной эффективностью
                выполнить любой вид работ. Поверхность "чернового" пола ровно и
                гладко формируется в итоге.
              </p>
            </div>
            <div>
              <h4>Затирочные устройства</h4>
              <p>
                После выполнения монтажа стяжки, следует побеспокоится о ее
                ровности. Затирочные машины устраняют мелкие шероховатости,
                создают защитную корку и идеально гладкую поверхность. При этом
                не нужно самостоятельно пользоватся затирочными щетками.
              </p>
            </div>
            <div>
              <h4>Пневмонагнетатель</h4>
              <p>
                С их помощью осуществляется подача готового раствора для
                проведения монтажных работ. Это компресорные устройства,
                способные подавать не только полусухие, но и жидкие смеси. Они
                имеют разнообразие моделей, которые оснащаются двигателями.
              </p>
            </div>
            <div>
              <h4>Лазерные нивелиры </h4>
              <p>
                Их применяют во время установки маяков по периметру помещения до
                заливки полусухой стяжки пола. Это сокращает время подготовки,
                перепады по высоте пола минимальны. Нивелиры применяются не
                только при устройстве полусухой стяжки пола, но и сухой, как в
                квартирах, так и в частных строениях.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Technology;

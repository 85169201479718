import React from "react";
import axios from "axios";

const API_PATH = "https://top-evropol.by/index.php";

class Connection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fname: "",
      lname: "",
      email: "",
      message: "",
      mailSent: false,
      error: null,
    };
  }

  handleFormSubmit(event) {
    event.preventDefault();
    console.log(this.state);
  }

  handleFormSubmit = (e) => {
    e.preventDefault();
    axios({
      method: "post",
      url: `${API_PATH}`,
      headers: { "content-type": "application/json" },
      data: this.state,
    })
      .then((result) => {
        this.setState({
          mailSent: true,
          fname: "",
          lname: "",
          email: "",
          message: "",
        });
      })
      .catch((error) => this.setState({ error: error.message }));
  };

  render() {
    return (
      <div className="Connection" id="Connection">
        <h4>Контакты</h4>
        <div className="Wrapper">
          <form action="#">
            <p>Отправить сообщение</p>
            <input
              type="text"
              id="fname"
              name="firstname"
              placeholder="ФИО"
              value={this.state.fname}
              onChange={(e) => this.setState({ fname: e.target.value })}
            />
            <input
              type="namber"
              id="lname"
              name="lastname"
              placeholder="Телефон"
              value={this.state.lname}
              onChange={(e) => this.setState({ lname: e.target.value })}
            />

            <input
              type="email"
              id="email"
              name="email"
              placeholder="Email"
              value={this.state.email}
              onChange={(e) => this.setState({ email: e.target.value })}
            />

            <textarea
              id="message"
              name="message"
              placeholder="Сообщение"
              onChange={(e) => this.setState({ message: e.target.value })}
              value={this.state.message}
            ></textarea>
            <div className="Success">
              {this.state.mailSent && (
                <div>
                  Спасибо за сообщение, мы свяжемся с вами как можно скорее!{" "}
                </div>
              )}
            </div>
            <input
              className="Submit"
              type="submit"
              onClick={(e) => this.handleFormSubmit(e)}
              value="Отправить
                    "
            />
          </form>
          <div className="Contacts">
            <p>+375 (29) 720-80-12 Владимир</p>
            <p>astorwest@yandex.ru</p>
          </div>
        </div>
      </div>
    );
  }
}

export default Connection;

import React from "react";
import whatsapp_bottom from "../images/whatsapp_bottom.png";
import Instagram_bottom from "../images/Instagram_bottom.png";
import telegram_bottom from "../images/telegram_bottom.png";
import viber_bottom from "../images/viber_bottom.png";

class Footer extends React.Component {
  render() {
    return (
      <div className="Footer">
        <div className="Wrapper">
          <div>
            <p>УНП 291 594 115</p>
            <p>Все права защищены</p>
          </div>
          <div>
            <div>
              <a href="https://wa.me/375297208012">
                <img src={whatsapp_bottom} alt="WhatsApp" />
              </a>
              <a href="https://instagram.com/vololiaboroda?igshid=18wn0md2oru5p">
                <img src={Instagram_bottom} alt="Instagram" />
              </a>
              <a href="#">
                <img src={telegram_bottom} alt="Telegram" />
              </a>
              <a href="https://viber.click/797208012">
                <img src={viber_bottom} alt="Viber" />
              </a>
            </div>

            <p>astorwest@yandex.ru</p>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;

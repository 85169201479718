import React from "react";
import "./App.css";
import Header from "./components/Header";
import Menu from "./components/Menu";
import Аdvantages from "./components/Аdvantages";
import AboutUs from "./components/AboutUs";
import Technology from "./components/Technology";
import Сalculator from "./components/Сalculator";
import Gallery from "./components/Gallery";
import Video from "./components/Video";
import Connection from "./components/Connection";
import Footer from "./components/Footer";

function App() {
  return (
    <div className="App">
      <Header />
      <Menu />
      <AboutUs />
      <Аdvantages />
      <Technology />
      <Сalculator />
      <Gallery />
      <Video />
      <Connection />
      <Footer />
    </div>
  );
}

export default App;

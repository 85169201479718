import React from "react";
import instagram from "../images/instagram.png";
import telegram from "../images/telegram.png";
import vatcap from "../images/vatcap.png";
import viber from "../images/viber.png";

class Header extends React.Component {
  render() {
    return (
      <div className="Header">
        <a href="#" className="Logo">
          Top-evropol
        </a>
        <div className="soc-contacts">
          <a href="https://wa.me/375297208012">
            <img src={vatcap} alt="WhatsApp" />
          </a>
          <a href="https://instagram.com/vololiaboroda?igshid=18wn0md2oru5p">
            <img src={instagram} alt="Instagram" />
          </a>
          <a href="#">
            <img src={telegram} alt="Telegram" />
          </a>
          <a href="https://viber.click/797208012">
            <img src={viber} alt="Viber" />
          </a>
          <p>+375297208012</p>
        </div>
        <a href="#Connection" className="Request">
          Оставить заявку
        </a>
      </div>
    );
  }
}

export default Header;

import React from "react";

class Сalculator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      square: "Введите лощадь м2",
      thick: "Введите толщину в см",
    };

    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  render() {
    let sand = (this.state.square * this.state.thick * 110) / 5;
    let сement = (this.state.square * this.state.thick * "13.75") / 5;
    let сost = (this.state.square * this.state.thick * 3.5) / 5;

    return (
      <div className="Сalculator" id="Сalculator">
        <h2>Калькулятор</h2>
        <p className="Many">
          Стоимость:
          <br />
          <span>
            3.5$ за м<sup>2</sup>
          </span>
          <br />
          <span className="Many-bottom">
            возможен наличный и безналичный рассчет
          </span>
        </p>
        <div className="Pricelists">
          <div className="Calculation">
            <h5>Рассчитать стоимость:</h5>
            <input
              name="square"
              type="text"
              value={this.state.square}
              onChange={this.handleInputChange}
            />
            <input
              name="thick"
              type="text"
              value={this.state.thick}
              onChange={this.handleInputChange}
            />
            <p className="value" id="Sand">
              <span>{sand}</span>кг Песка
            </p>
            <p className="value" id="Сement">
              <span>{сement}</span>кг Цемента
            </p>
            <p className="value" id="Сost">
              за работу <span>{сost}</span> $
            </p>
          </div>
          <div className="Price">
            <h5>Цены на услуги:</h5>
            <div className="Square">
              <p className="House">Дома</p>
              <div>
                <p>
                  0-30 м<sup>2</sup>
                </p>
                <p>
                  30-59 м<sup>2</sup>
                </p>
                <p>
                  60-90 м<sup>2</sup>
                </p>
                <p>
                  от 90 м<sup>2</sup>
                </p>
                <p className="TitlePris">
                  Засыпка
                </p>
              </div>
              <div>
                <p>- от 400р</p>
                <p>
                  - 12р за м<sup>2</sup>
                </p>
                <p>
                  - 10р за м<sup>2</sup>
                </p>
                <p>
                  - 9р за м<sup>2</sup>
                </p>
                <p>
                  - 35р за м<sup>2</sup>
                </p>
              </div>
            </div>
            <div>
              <p className="House">Квартиры</p>
              <p className="Housecolor">Цена указана с нашими материалами</p>
              <div className="Square2">
                <p className="Apartment">
                  <span>
                    0-10 м<sup>2</sup>
                  </span>{" "}
                  <br />
                  450р
                </p>
                <p className="Apartment">
                  <span>
                    11-15 м<sup>2</sup>
                  </span>{" "}
                  <br />
                  500р
                </p>
                <p className="Apartment">
                  <span>
                    16-20 м<sup>2</sup>
                  </span>{" "}
                  <br />
                  550р 
                </p>
                <p className="Apartment">
                  <span>
                    20-50 м<sup>2</sup>
                  </span>{" "}
                  <br />
                  26р за м
                </p>
                <p className="Apartment">
                  <span>
                  от 50 м<sup>2</sup>
                  </span>{" "}
                  <br />
                  25р за м
                </p>
              </div>
              <p className="Sale">
                Безнал 240 указ РБ - 10р м<sup>2</sup>
                <br /> Безнал юр лица - 10р м<sup>2</sup>
                <br /> Крыша от - 10р м<sup>2</sup>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Сalculator;

import React from "react";

class Video extends React.Component {
  render() {
    return (
      <div className="Video ">
        <h3>Видео</h3>
        <div className="Wrapper">
          <div className="Vidos">
            <iframe
              width="806"
              height="570"
              title="video"
              allowFullScreen
              src="https://www.youtube.com/embed/oKPI2cglFZo"
              loading="lazy"
              async
            ></iframe>
          </div>
          <div className="Videos">
            <iframe
              width="375"
              height="270"
              title="video"
              allowFullScreen
              src="https://www.youtube.com/embed/8g5e8xaYSDs"
              loading="lazy"
              async
            ></iframe>
            <iframe
              width="375"
              height="270"
              title="video"
              allowFullScreen
              src="https://www.youtube.com/embed/S9Ckqt94D4A"
              loading="lazy"
              async
            ></iframe>
            <iframe
              width="375"
              height="270"
              title="video"
              allowFullScreen
              src="https://www.youtube.com/embed/S9_irwuWaFk"
              loading="lazy"
              async
            ></iframe>
            <iframe
              width="375"
              height="270"
              title="video"
              allowFullScreen
              src="https://www.youtube.com/embed/0DXxKnxhjsA"
              loading="lazy"
              async
            ></iframe>
          </div>
        </div>
      </div>
    );
  }
}

export default Video;

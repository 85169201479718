import React from "react";

class Img extends React.Component {
  render() {
    return (
      <div className="img-holder">
        <img src={this.props.src} alt="Пример работы" />
      </div>
    );
  }
}

export default Img;

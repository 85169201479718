import React from "react";

class Аdvantages extends React.Component {
  render() {
    return (
      <div className="Аdvantages" id="Аdvantages">
        <h2>О компании</h2>
        <div className="Аdvantages-content">
          <div>
            <p>
              <span>18 лет</span>
              Опыт работы
            </p>
          </div>
          <div>
            <p>
              <span>
                550k м<sup>2</sup>
              </span>
              Ровных полов
            </p>
          </div>
          <div>
            <p>
              <span>
                150 м<sup>2</sup>
              </span>
              За день
            </p>
          </div>
          <div>
            <p>
              <span>5 лет</span>
              Гарантия
            </p>
          </div>
          <div>
            <p>
              <span>
                Наши
                <br /> полы
              </span>
              Идеально ровные
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default Аdvantages;
